.landing-wrapper {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .landing-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #293897;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideUp 0.7s ease forwards;
    opacity: 1;
  }
  
  .second {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 18rem;
  }
  
  
  
  .progress-bar {
    width: 37.5rem;
    height: 1.25rem;
    border-radius: 5rem;
    background: var(--linear-orange, linear-gradient(118deg, #EE4622 0%, rgba(238, 70, 34, 0.50) 100%));
    transition: width 0.2s ease;
  }
  
  .progress-bar-container {
    width: 37.5rem;
    height: 1.25rem;
    border-radius: 5rem;
    background: rgba(231, 231, 231, 0.60);
    margin-top: 1rem;
    animation: zoomOut 0.2s ease-in-out forwards;
  }
  
  .progress-bar-container {
    display: none;
  }
  
  .second img[style*="20%"] + .progress-bar-container {
    display: block;
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  @keyframes zoomOut {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }