.clientData {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
}

.leftDetail {
  border-radius: 1rem;
  background: #fff;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 28%;
  height: fit-content;
}

.leftDetail h2 {
  color: #000;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.insideLeftDetail {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 95%;
}

.insideLeftDetail h3 {
  color: #7c7c80;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.insideLeftDetail input {
  border-radius: 0.25rem;
  border: 1px solid #c5c5c5;
  display: flex;
  padding: 0.875rem 0.0625rem 0.875rem 0.75rem;
  align-items: center;
  gap: 0.625rem;

  color: #242426;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rightDetail {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1.38rem;
}

.topRightDetail {
  display: flex;
  justify-content: space-between;
}

.topRightDetail div {
  border-radius: 0.5rem;
  background: #fff;

  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 1rem 1rem 1rem 1.0625rem;
  align-items: flex-start;
  gap: 0.625rem;
}

.topRightDetail h4 {
  color: #4a6afe;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.topRightDetail h5 {
  color: #646464;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottomRightDetail {
  border-radius: 1rem;
  background: #fff;
  min-height: 60vh;

  padding: 0rem 1.25rem;
}

.RightTable {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0rem;
  align-items: center;
  border-bottom: 1px solid #e5e5ea;
}

.RightTable div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.RightTable div:nth-child(3) {
  flex-direction: row;
}

.RightTable h3 {
  color: #000;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.RightTable h4 {
  color: #7c7c80;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bookedButton {
  border-radius: 6.25rem;
  background: #d1d9ff;
  display: flex;
  padding: 0.5rem 0.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;

  color: #4b68fe;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.resCode {
  color: #4a6afe;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
