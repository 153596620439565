@tailwind base;
@tailwind components;
@tailwind utilities;
@import "rsuite/dist/rsuite.css";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App{
  display: flex;
  width: 100%;
}
.sidebar-wrapper{
  position: sticky;
  top: 0;
  height: 100vh;
}
.inside-app{
  flex: 1;
}

a:hover, a:focus {
  text-decoration: none;
}