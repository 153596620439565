.inner-input input:-webkit-autofill,
.inner-input input:-webkit-autofill:hover,
.inner-input input:-webkit-autofill:focus,
.inner-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  border: 1px solid #4b68fe;
}

.emailbox{
  align-items: center;
}

.emailimg{
  height: 5rem;
  width: 5rem;
}
.default-pass{
  color: #767676;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  width: 80%;
  display: flex;
  margin: 0 auto;
}

.image-text {
  color: #404040;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.0175rem;
  margin-top: 0.5rem;
}

.inner-input input:focus {
  background-color: transparent;
  border-color: black;
}

.custom-upload {
  border-radius: 0.5rem;
  background: rgba(217, 223, 255, 0.5);
}

.ant-modal-footer {
  background-color: #2563eb;
  border: none;
}

.ant-btn-primary {
  background-color: #2563eb !important;
  border-color: #2563eb !important;
  border: none;
}

:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select
  > .ant-upload {
  width: 8rem;
  height: 8rem;
  border: none;
}
:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: 8rem;
  height: 8rem;
  border: none;
}

:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item {
  height: 8rem;
  width: 8rem;
  padding: 0rem;
  border: none;
  border-radius: 0.5rem;
  margin-right: 1.38rem;
}

:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before {
  width: 100%;
  height: 100%;
}

:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail {
  border-radius: 0.5rem;
}

:where(.css-1ae8k9u).ant-upload-wrapper.ant-upload-picture-card-wrapper{
  display: inline;
}

.noBookingWrapper{
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}


.noBookingWrapper>img{
  width: 25rem;
  height: 14.8rem;
}

.noBookingWrapper h2{
  color: #000;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.noBookingWrapper>h3{
  color: #404040;
text-align: center;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.0175rem;
}


.flag-dropdown{
  border: none !important;
  margin: 0 !important;
  border-radius: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
} 

.flag-dropdown:hover{
  background: #EFF0F080 !important;
}

.react-tel-input .form-control{
  border: none !important;
  background: #EFF0F080 !important;
  border-radius: 0.5rem !important;
  padding-left: 3.5rem !important;
}

/* ///// */

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.popup-content img {
  max-width: 100%;
  max-height: 70vh; /* Adjust as needed */
  display: block;
  margin: 0 auto;
}

.close-btn {
  position: absolute;
  top: 31px;
  right: 41px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.phoneNumberInput {
  cursor: not-allowed;
}
