:root,
.rs-theme-light {
  --rs-primary-50: #4b68fe;
  --rs-primary-100: #cfd7ff;
  --rs-primary-200: #4b68fe;
  --rs-primary-300: #4b68fe;
  --rs-primary-400: #4b68fe;
  --rs-primary-500: #4b68fe;
  --rs-primary-600: #4b68fe;
  --rs-primary-700: #4b68fe;
  --rs-primary-800: #4b68fe;
  --rs-primary-900: #4b68fe;
  --rs-color-red: #4b68fe;
}

.custom-dropdown {
  width: 100%;
}
.dropdown-header {
  display: flex;
  padding: 0.83rem 1.3rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #d8d8dc;
  color: #242426;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  line-height: normal;
}

.chevron-icon {
  width: 1rem;
  height: 1rem;
  color: #7c7c80;
}
.Time-placeHolder {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-header.active {
  border: 1px solid #4b68fe;
}
.dropdown-header > div {
  width: 100%;
}

.Time-placeHolder > div:nth-child(1) {
  display: flex;
  gap: 0.5rem;
}
.selectedPeopleInput::placeholder,
.Selected-place {
  color: #242426;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.selectedPeopleInput {
  height: 4rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem !important;
}
.Selected-place {
  position: absolute;
  left: 2.5rem;
  top: 8.25rem;
}
.Time-placeholder-child {
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Time-Value,
.Time-placeholder-child-selected {
  color: #4b68fe;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .dropdown-packageOptions {
  border: none;
  width: 12%;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  position: absolute;
  top: 25%;
  z-index: 1;
  gap: 1rem;
} */

.dropdown-packageOptions-two {
  border: none;
  width: 12%;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  position: absolute;
  top: 12rem;
  z-index: 1;
  gap: 1rem;
}
.dropdown-packageOptions {
  border: none;
  width: 12%;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  position: absolute;
  top: 20rem;
  z-index: 1;
  gap: 1rem;
}
.dropdown-header {
  position: relative;
}

.dropdown-option {
  display: flex;
  padding: 0.625rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  background: #fff;
  color: #242426;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  position: relative;
}

.dropdown-packageOptions-status {
  border: none;
  width: 12%;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  position: absolute;
  top: 27%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.dropdown-option-status {
  display: flex;
  padding: 0.5rem 0.8rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6.25rem;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  position: relative;
}
.dropdown-option-status:nth-child(1) {
  background: #cceacc;
  color: #009600;
}
.dropdown-option-status:nth-child(2) {
  background: #ffeacc;
  color: #ff9800;
}
.dropdown-option-status:nth-child(3) {
  background: #fdd9d7;
  color: #f44336;
}

.dropdown-option.selected {
  background-color: #4b68fe;
  color: white;
}

.rs-btn-primary {
  background-color: #4b68fe;
}
.rs-btn-primary:hover {
  background-color: #4b68fe;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #4b68fe;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #4b68fe;
}

.rs-picker-daterange-predefined {
  padding-top: 4rem;
  gap: 0.5rem !important;
}

.rs-btn-link {
  color: #4b68fe;
}

.rs-btn-link:hover {
  color: #4b68fe;
  text-decoration: none;
}

.rs-picker-daterange-header {
  display: none;
}

.dropdown-header.active {
  border: 1px solid #4b68fe;
}

.rs-input-group.rs-input-group-inside {
  /* width: 90%; */
  height: 2.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-picker-toggle-wrapper {
  display: flex;
  vertical-align: bottom;
  justify-content: flex-end;
  max-width: 100%;
}

.noBookingWrapperTwo{
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}


.noBookingWrapperTwo>img{
  width: 25rem;
  height: 14.8rem;
}

.rs-picker-popup{
  z-index: 100;
}

.insideDetails .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input,
.insideDetails .rs-input-group.rs-input-group-inside {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.insideDetails .rs-input-group-lg > .rs-input {
  color: black;
  font-size: 0.89rem;
}




.noBookingWrapperTwo h2{
  color: #000;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.noBookingWrapperTwo>h3{
  color: #404040;
text-align: center;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.0175rem;
}

input:focus {
  outline: none;
  /* border: 1px solid red !important */
}
