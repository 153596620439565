.membersParent {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #fff;
  width: 100%;
}

.membersParent>h3 {
  color: #888;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.membersParent h4 {
  width: 100%;
  display: flex;
  justify-content: end;
  color: #707070;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02rem;
}

.membersChild {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.membersChild h2 {
  color: #242426;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.0275rem;
}

.membersChild button {
  display: flex;
  padding: 0.625rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #4b68fe;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02rem;
}
