.inner-input input:-webkit-autofill,
.inner-input input:-webkit-autofill:hover,
.inner-input input:-webkit-autofill:focus,
.inner-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  border: 1px solid #4b68fe;
}

.inner-input input:focus {
    background-color: transparent;
    border-color: black;
  }

.signin {
    background-color: rgb(243, 244, 254);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signin-inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.signin-inner h1 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

.signin-inner input {
    border-radius: 0.5rem;
    border: 1px solid #E3E3E3;
    background: #FFF;
    width: 45.375rem;
    height: 4.375rem;
    padding: 1.4375rem 1.875rem;

    color: #8D8D8D;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
}

.signin-inner button {
    width: 45.375rem;
    padding: 1.28125rem 3.125rem;
    border-radius: 0.5rem;
    border: none;
    color: white;
    background: var(--blue, #4A69FF);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
}

.checkbox {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
}

.checkbox input[type="checkbox"] {
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius: 0.125rem;
    border: 1px solid var(--stroke, rgba(74, 105, 255, 0.50));
    background: #FFF;
}

.checkbox input[type="checkbox"]:checked {
    border-color: #293897; 
    background-color: #293897; 
}


.checkbox p {
    color: #404040;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    color: #404040;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}